<template>
  <v-list three-line>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon :color="menuItemColorClass">{{ iconTask }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ taskName }}</v-list-item-title>
        <v-list-item-subtitle>
          <strong>{{ projectLabel }}</strong
          >{{ projectName }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { iconTask } from "@/design/icon/iconConst";
import { projectLabel } from "@/model/workflow/project/projectModel";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

export default {
  name: "TaskDialogHeader",
  mixins: [menuItemSettingsMixin],
  props: {
    /**
     * user task
     * @type {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee: string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole: string, canReassign: boolean, canSelfRelease: boolean, selfReleaseDisabled: false, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone: boolean, isOverdue: boolean, notifyOnEscalation:boolean, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, selfReleaseDisabled: boolean, workflowDefinitionId: number, notes: {id:number, taskId:number, text:string, userId:number, userName: string, created: string}[]}}
     */
    task: undefined
  },
  data() {
    return {
      iconTask: iconTask
    };
  },
  computed: {
    /**
     * task Name
     * @return {string}
     */
    taskName() {
      return this.task?.name ?? "";
    },

    /**
     * task's project Name
     * @return {string}
     */
    projectName() {
      return this.task?.projectName ?? "";
    },

    /**
     * project label
     * @return {string}
     */
    projectLabel() {
      return `${projectLabel}: `;
    }
  }
};
</script>
